<!--
 * @Descripttion: 分账支付订单列表
 * @version: 
 * @Author: dxl
 * @Date: 2023-08-20 14:26:00
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-28 15:19:17
-->
<template>
    <div class="LedgerOrderList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">分账支付订单列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <el-select clearable v-model="state" @change="stateChange" placeholder="请选择缴费类型">
                    <el-option
                        v-for="item in stateOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="search-item">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border stripe style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="70"></el-table-column>
                    <el-table-column align="center" prop="pay_sn" label="微信订单编号" width="270" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="sn" label="平台编号" width="170" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="title" label="缴费类型" width="190" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="epRemark.remark_shop_name" label="经销商" width="170" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="epRemark.remark_shop_address" label="经销商地区" width="170" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="pay_real_price" label="支付金额" width="170" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="pay_time" label="支付时间" width="170" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="promoter.name" label="推广人"  width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="promoter.mobile" label="推广人手机号"  width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="promotion_code" label="推广码"  width="150"></el-table-column>
                    <el-table-column align="center" prop="PromotionSplitPriceName" label="分账金额" width="100" :show-overflow-tooltip="true"  fixed="right"></el-table-column>
                    <el-table-column align="center" prop="PromotionSplitSuccess" label="状态" width="170" :show-overflow-tooltip="true"  fixed="right">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.PromotionSplitSuccess==1" size="medium" type="success">已完成</el-tag>
                            <el-tag v-if="scope.row.PromotionSplitSuccess==0" size="medium" type="info">未完成</el-tag>
                            <el-tag v-if="scope.row.PromotionSplitSuccess==0 && scope.row.promoter_split_count>0" size="medium" type="danger">失败</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="100"  fixed="right">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item pointer" effect="dark" content="分账" placement="top">
                                    <i v-if="scope.row.PromotionSplitSuccess==0" @click="clickFZ(scope.row)" class="iconfontx el-icon-s-finance"></i>
                                    <i v-else class="iconfont el-icon-s-finance"></i>
                                </el-tooltip>
                                
                                <el-tooltip v-if="scope.row.promoter_split_count==0"  class="item" effect="dark" content="无分账记录" placement="top">
                                    <i class="iconfont el-icon-document-delete"></i>
                                </el-tooltip>
                                <el-tooltip  v-if="scope.row.promoter_split_count>0 && scope.row.PromotionSplitSuccess==1"  class="item" effect="dark" content="查看分账记录" placement="top">
                                    <i @click="clickFZ_order(scope.row)" class="link iconfont el-icon-document-checked"></i>
                                </el-tooltip>
                                <el-tooltip  v-if="scope.row.promoter_split_count>0 && scope.row.PromotionSplitSuccess==0"  class="item" effect="dark" content="查看分账记录" placement="top">
                                    <i @click="clickFZ_order(scope.row)" class="iconfontx el-icon-document-checked"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="currentPageSize"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'LedgerOrderList',
            state: '',
            stateOptions:[{
                value: 1,
                label: '开通'
            },{
                value: 2,
                label: '续费'
            }],
            loading: false,
            tableData: [],
            currentPage: 1,
            currentPageSize: 20,
            total: 0,
            keyword: '',
            promotion_openid:'',
        }
    },
    mounted () {
        this.commissionerParam = this.$route.query
        if(this.commissionerParam.openid){
            this.promotion_openid = this.commissionerParam.openid;
        }

        this.getLedgerOrderList();
    },
    methods: {
        // 分账
        clickFZ(row){
            let params = {
                openid: row.promotion_openid,
                pay_sn: row.pay_sn,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/profitSharing',params,(res)=>{
                this.getLedgerOrderList();
                this.loading = false;
            })
        },
        // 
        clickFZ_order(row){
            let newPage = this.$router.resolve({
                path: 'LedgerOrderSplitList',
                query: {
                    pay_sn: row.pay_sn,
                }
            });
            window.open(newPage.href, '_blank');
        },
        // 获取数据
        getLedgerOrderList(){
            let params = {
                page: this.currentPage,
                page_size: this.currentPageSize,
                keyword: this.keyword,
                type: this.state,
            }
            if(this.promotion_openid){
                params.promotion_openid = this.promotion_openid;
            }
            // console.log(params);
            this.loading = true;
            common.connect('/customerservicev1/Promoter/getPromoterOrderList',params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getLedgerOrderList();
        },
        // 状态选择
        stateChange(){
            this.currentPage = 1;
            this.getLedgerOrderList();
        },
        //翻页
        handleCurrentChange(val){
            this.currentPage = val;
            this.getLedgerOrderList();
        },
    },
}
</script>

<style lang='scss'>
.pointer{
  cursor: pointer;
}
.LedgerOrderList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
            .search-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 20px;
                .el-input{
                    width: 220px;
                    margin-right: 10px;
                }
            }
        }
        .table-wrap{
            margin-top: 20px;
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                .iconfont{
                    font-size: 17px;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .iconfontx{
                    font-size: 17px;
                    color: red;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .link{
                    cursor: pointer;
                    color: #409EFF;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 540px;
        .title{
            text-align: center;
            font-size: 18px;
            color: #000;
        }
        .content{
            margin-left: 20px;
            .el-input{
                width: 500px;
            }
            .el-textarea{
                width: 500px;
            }
            .item{
                padding-bottom: 20px;
                .tit{
                    padding-bottom: 20px;
                    color: #000;
                }
                .el-button{
                    width: 500px;
                }
                .upload-wrap{
                    .fileList-box{
                        position: relative;
                        width: 250px;
                        border: 1px solid #eee;
                        border-radius: 6px;
                        padding: 20px 20px;
                        .delFile{
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            background: rgba(0, 0, 0, 0.2);
                            padding: 0 5px;
                            border-radius: 10px;
                            color: #fff;
                            cursor: pointer;
                            
                        }
                        .link{
                            width: 230px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .icon{
                                display: flex;
                                flex-wrap: wrap;
                                align-items: baseline;
                                .iconfont{
                                    font-size: 14px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
